<template>
  <div class="form-reset-password">
    <div class="title">
      <span>{{ FORM_AUTH.RESET_PASSWORD }}</span>
    </div>
    <form class="form-reset-password__container">
      <BaseInput
        ref="passwordRef"
        v-model.trim="password"
        :label="FORM_AUTH.LABEL_RESET_PASSWORD"
        type="password"
        :max-length="$config.MAX_LENGTH_PASSWORD"
        :min-length="$config.MIN_LENGTH_PASSWORD"
        :limit-input="$config.MAX_LENGTH_PASSWORD"
        :placeholder="`Nhập từ ${$config.MIN_LENGTH_PASSWORD}-${$config.MAX_LENGTH_PASSWORD} ký tự`"
        required
        error-required="Vui lòng nhập mật khẩu mới"
        @keyup.enter="confirmPasswordRef?.focus()"
      />
      <BaseInput
        ref="confirmPasswordRef"
        v-model.trim="confirmPassword"
        type="password"
        :label="FORM_AUTH.LABEL_RESET_CONFIRM_PASSWORD"
        :same-as="password"
        :max-length="$config.MAX_LENGTH_PASSWORD"
        :min-length="$config.MIN_LENGTH_PASSWORD"
        :limit-input="$config.MAX_LENGTH_PASSWORD"
        required
        :placeholder="`Nhập từ ${$config.MIN_LENGTH_PASSWORD}-${$config.MAX_LENGTH_PASSWORD} ký tự`"
        error-required="Vui lòng nhập lại mật khẩu mới"
        @keyup.enter="submit"
      />
      <BaseButton
        class="base-button--btn-primary"
        :is-loading="isLoadingSubmit"
        :disabled="!password || !confirmPassword"
        type="submit"
        @click="submit"
      >
        Xác nhận
      </BaseButton>
    </form>
  </div>
</template>
<script setup lang="ts">
import { FORM_AUTH } from '~/constants/form/auth'
import BaseInput from '~/components/common/base-input.vue'
import BaseButton from '~/components/common/base-button.vue'
import { useResetPassword } from '~/composables/user/useResetPassword'
const { $config } = useNuxtApp()
const { submit, password, confirmPassword, passwordRef, confirmPasswordRef, isLoadingSubmit } = useResetPassword()
</script>
<style lang="scss" scoped src="assets/scss/components/common/form/reset-password.scss"></style>
